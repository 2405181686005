<template>
  <div class="team-gallery">
    <div
      v-for="(member, index) in $t('teamgallery.members')"
      :key="index"
      :id="`member-${index}`"
      :class="memberClass(member.slug, activeMember, index)"
      v-on:click="setActiveMember(index, `member-${index}`)"
    >
      <div class="team-gallery__sideline">{{ member.memberinfo[0] }}</div>
      <router-link
        class="team-gallery__wrapper-link"
        :to="`/about/team/${member.slug}`"
        >{{ member.memberinfo[0] }}</router-link
      >
      <div class="team-gallery__body">
        <router-link
          class="team-gallery__name"
          :to="`/about/team/${member.slug}`"
          >{{ member.memberinfo[0] }}</router-link
        >
        <a :href="`${member.memberinfo[1]}`" v-html="member.memberinfo[2]"></a>
        <router-link
          class="team-gallery__link"
          :to="`/about/team/${member.slug}`"
          >{{ $t("teamgallery.link") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { polyfill } from "seamless-scroll-polyfill";

polyfill();

export default {
  name: "TeamGallery",
  props: {
    passedActiveMember: Number,
  },
  data() {
    return {
      activeMember: 0,
    };
  },
  methods: {
    setActiveMember(index, memberId) {
      this.activeMember = index + 1;
      if (window.innerWidth < 1024) {
        setTimeout(
          () =>
            document
              .getElementById(memberId)
              .scrollIntoView({ block: "center", behavior: "smooth" }),
          500
        );
      } else {
        setTimeout(() => window.scrollTo({ top: 1, behavior: "smooth" }), 500);
      }
    },
    memberClass(memberSlug, activeMember, index) {
      let pathArray = this.$route.path.split("/"),
        pathLength = pathArray.length,
        path = pathArray[pathLength - 1];

      if (path === memberSlug || activeMember === index + 1) {
        return `team-gallery__member team-gallery__member--${memberSlug} team-gallery__member--active`;
      } else {
        return `team-gallery__member team-gallery__member--${memberSlug}`;
      }
    },
  },
};
</script>

<style lang="scss">
.team-gallery {
  background-color: $color-primary-50;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  @media (min-width: $size-md) {
    flex-direction: row;
    flex-wrap: nowrap;
  }

  @media (min-width: $size-xl) {
    .team & {
      margin-left: -70px;
      margin-right: -70px;
    }
  }

  &__member {
    $height-xs: 10rem;
    $height-xs-active: 22rem;
    $height-md: 15rem;
    $height-xl: 20rem;
    $height-2x: 25rem;

    @media (min-width: $size-sm) {
      background-position-x: 38%;
      flex-basis: 33.3%;
    }

    @media (min-width: $size-md) {
      background-position-x: 38%;
      flex: 1 1 10%;
      height: $height-md;
    }

    @media (min-width: $size-lg) {
      background-position-x: 38%;
    }

    @media (min-width: $size-xl) {
      height: $height-xl;
    }

    @media (min-width: $size-2x) {
      height: $height-2x;
    }

    align-self: baseline;
    background-position: 32% center;
    background-size: cover;
    box-shadow: inset 2rem 2rem 2rem rgba($color-black, 0.1);
    flex: 0 1 50%;
    height: $height-xs;
    letter-spacing: 0.41px;
    overflow: hidden;
    position: relative;
    transition: all $transition-duration * 3 $transition-easing;

    .team &:not(.team-gallery__member--active) {
      flex-basis: 0;

      @media (min-width: $size-lg) {
        flex: 1;
      }
    }

    &--marcus-honkanen {
      background-image: url("../assets/images/team/marcus-honkanen.jpg");
      @media (min-width: $size-lg) {
        background-position-x: 35%;
      }
    }

    &--fanny-aberg {
      background-image: url("../assets/images/team/fanny-aberg.jpg");
      @media (min-width: $size-sm) {
        background-position-x: 35%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 35%;
      }
    }

    &--christian-fuchs {
      background-image: url("../assets/images/team/christian-fuchs.jpg");
    }

    &--christian-tegethoff {
      background-image: url("../assets/images/team/christian-tegethoff.jpg");
      @media (min-width: $size-sm) {
        background-position-x: 42%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 42%;
      }
    }

    &--maike-van-den-boom {
      background-image: url("../assets/images/team/maike-van-den-boom.jpg");
      @media (min-width: $size-sm) {
        background-position-x: 42%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 42%;
      }
    }

    &--christopher-grahn {
      background-image: url("../assets/images/team/christopher-grahn.jpg");
    }

    &--jasmin-christensen {
      background-image: url("../assets/images/team/jasmin-christensen-1.jpg");
      @media (min-width: $size-sm) {
        background-position-x: 43.5%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 43.5%;
      }
    }
    &--lotta-veikonheimo {
      background-image: url("../assets/images/team/nm_profile_img_lotta.jpg");
      @media (min-width: $size-sm) {
        background-position-x: 43%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 43%;
      }
    }
    &--christian-hoppe {
      background-image: url("../assets/images/team/christian-hoppe.jpg");
      @media (min-width: $size-sm) {
        background-position-x: 44%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 44%;
      }
    }
    &--anja-golumbeck {
      background-image: url("../assets/images/team/nm_profile_img_anja-1.jpeg");
      @media (min-width: $size-sm) {
        background-position-x: 43%;
      }
      @media (min-width: $size-lg) {
        background-position-x: 43%;
      }
    }

    &:before {
      background: #003a42;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      opacity: 0.4;
      position: absolute;
      right: 0;
      top: 0;
      transition: all $transition-duration * 2 $transition-easing;
      z-index: 10;
    }

    &:hover {
      @media (min-width: $size-md) {
        flex-basis: 32%;
        height: $height-md;
      }

      @media (min-width: $size-xl) {
        flex-basis: 45%;
        height: $height-xl;
      }

      @media (min-width: $size-2x) {
        height: $height-2x;
      }

      &:before {
        opacity: 0;
      }
    }

    &--active {
      @media (min-width: $size-sm) {
        flex-basis: 66.6%;
      }

      @media (min-width: $size-md) {
        flex-basis: 32%;
        height: $height-md;
      }

      @media (min-width: $size-xl) {
        background-position-x: 80%;
        flex-basis: 45%;
        height: $height-xl;
      }

      @media (min-width: $size-2x) {
        height: $height-2x;
      }

      box-shadow: inset 2rem 2rem 2rem rgba($color-black, 0);
      flex: 1 1 100%;
      height: $height-xs-active;
      max-width: none;

      &:before {
        opacity: 0;
      }
    }

    &:not(.team-gallery__member--active):hover {
      .team & {
        @media (min-width: $size-lg) {
          background-position-x: 40%;
          flex-basis: 9%;
        }
      }
    }

    &--active {
      .team & {
        @media (min-width: $size-md) {
          background-position-x: 40%;
          flex-basis: 18%;
        }
      }
    }
  }

  &__sideline {
    @media (min-width: $size-md) {
      font-size: 0.8rem;
      text-orientation: sideways;
      transform: rotate(180deg);
      writing-mode: vertical-rl;

      .team-gallery__member:hover & {
        opacity: 0;
      }

      .team .team-gallery__member:not(.team-gallery__member--active):hover & {
        opacity: 1;
        text-shadow: 0 0 2rem rgba($color-black, 1);
      }
    }

    @media (min-width: $size-xl) {
      bottom: 1.5rem;
      font-size: 0.9rem;
    }

    bottom: 1rem;
    color: $color-white;
    line-height: 1;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    right: 0.7rem;
    text-align: right;
    transition: all $transition-duration $transition-easing;
    z-index: 15;

    .team-gallery__member--active & {
      opacity: 0;
    }
  }

  &__wrapper-link {
    @media (min-width: $size-lg) {
      display: block;
      right: 0;
    }

    bottom: 0;
    left: 0;
    position: absolute;
    right: 100%;
    text-indent: 110vw;
    top: 0;
    transition: all 0.01s $transition-duration * 3.5;
    white-space: nowrap;
    z-index: 12;

    .team-gallery__member:hover & {
      right: 0;
    }
  }

  &__body {
    @media (min-width: $size-sm) {
      right: auto;
    }
    @media (min-width: $size-md) {
      background-color: transparent;
      bottom: 0.7rem;
      left: auto;
      padding: 0;
      right: 1rem;
      text-align: right;
      text-shadow: 0 0 2rem rgba($color-black, 1);
      top: auto;

      .team-gallery__member:hover & {
        opacity: 1;
        pointer-events: all;
        z-index: 15;
      }

      .team .team-gallery__member:not(.team-gallery__member--active):hover & {
        opacity: 0;
      }
    }

    @media (min-width: $size-xl) {
      font-size: 0.8rem;
      width: 70%;
    }

    background-color: $color-primary-200;
    bottom: 0;
    font-size: 0.7rem;
    left: 1rem;
    opacity: 0;
    padding: 1rem;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    transition: all $transition-duration $transition-easing $transition-duration *
      2;
    white-space: nowrap;

    .team-gallery__member--active & {
      opacity: 1;
      pointer-events: all;
      z-index: 15;
    }

    .team .team-gallery__member--active & {
      @media (min-width: $size-lg) {
        bottom: 0.8rem;
        text-align: right;
        top: auto;
        width: auto;
      }
    }

    .team .team-gallery__member:not(.team-gallery__member--active):hover & {
      bottom: 1.2rem;
      top: auto;
      width: auto;
    }

    a {
      color: $color-white;

      &:not([class]) {
        display: block;
        line-height: 1;
        margin-bottom: 0.2em;
        white-space: break-spaces;
        word-break: break-word;
        word-wrap: break-word;
      }

      &[href^="mailto"] {
        @media (min-width: $size-md) {
          overflow: visible;
        }

        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      br {
        display: none;

        @media (min-width: $size-md) {
          display: block;
        }

        .team .team-gallery__member--active & {
          display: none;
        }
      }

      .team
        .team-gallery__member:not(.team-gallery__member--active):hover
        &:not(.team-gallery__name) {
        display: none;
      }
    }
  }

  &__name {
    @media (min-width: $size-md) {
      line-height: 1;
      margin-bottom: 0.5rem;
    }

    @media (min-width: $size-xl) {
      font-size: 1.1rem;
    }

    color: $color-white;
    display: block;
    font-family: $font-family-multi-display;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  &__link {
    background: url("../assets/images/arrow.svg") no-repeat left center;
    background-size: 1rem;
    color: $color-white;
    display: inline-block;
    margin-top: 0.5rem;
    padding: 0 0 0.25rem 1.5rem;

    .team .team-gallery__member--active & {
      display: none;
    }
  }
}
</style>
